import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';

import { CoordinateComponent } from './coordinate.component';
import { TransformationFormModule } from '../transformation-form/transformation-form.module';

@NgModule({
  declarations: [
    CoordinateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TransformationFormModule
  ],
  exports: [
    CoordinateComponent
  ]
})
export class CoordinateModule { }
