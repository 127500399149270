import { Injectable } from '@angular/core';
import Decimal from 'decimal.js';

export type DmsLabelType = {
  a: string,
  b: string,
  c: string,
}

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  DefPrecision = 3;
  DPrecision = 9;
  MPrecision = 7;
  SPrecision = 5;

  degreeTypes: string[] = ['D', 'DM', 'DMS'];
  minus = "-";
  dot = ".";
  deg = "°";
  quote = "'";

  constructor() { }

  fetchCoordLabels(coordType: string, projection: string) {
    // aggregate similar projection names
    if (projection.includes('UTM')) {
      projection = 'UTM';
    } else if (projection.includes('ETSR')) {
      projection = 'ETSR';
    } else if (projection.includes('LAMBERT')) {
      projection = 'LAMBERT';
    }

    let labels: DmsLabelType = { a: 'x', b: 'y', c: 'H' };

    switch (coordType) {
      case 'PLANE':
        switch (projection) {
          case 'LAMBERT':
          case 'ETSR':
            labels = { a: 'x', b: 'y', c: 'H' };
            break;
          case 'UTM':
            labels = { a: 'E', b: 'N', c: 'H' };
            break;
        }
        break;
      case 'GEOGRAPHIC':
        labels = { a: 'λ', b: 'Φ', c: 'h' };
        break;
      case 'GEOCENTRIC':
        labels = { a: 'X', b: 'Y', c: 'Z' };
        break;
    }
    return labels;
  }

  formatInputCoord(coord: string, degreeType: string, isLat: boolean) {
    let isMinus = false;
    // remove temporary the minus sign
    if (coord.charAt(0) === "-") {
      isMinus = true;
      coord = coord.substring(1);
    }
    // replace all comma by dot
    coord = coord.replace(/,/g, '.');

    let output: string = coord;
    switch (degreeType) {
      case 'D':
        output = this.formatInputD(coord, isLat);
        break;
      case 'DM':
        output = this.formatInputDM(coord, isLat);
        break;
      case 'DMS':
        output = this.formatInputDMS(coord, isLat);
        break;
    }
    return (isMinus) ? "-" + output : output;

  }

  private formatInputD(value: string, isLat: boolean) {
    const dotPosition = (isLat) ? 2 : 1;
    // exclude all char but digits, dots
    value = value.replace(/[^0-9\.]*/g, '');
    // keep only the firt dot char
    value = this.keepOnlyFirstChar(value, this.dot);
    value = this.putSymbolInRightPosition(value, this.dot, dotPosition);
    value = this.manageZerosBeforeDot(value);
    value = this.truncateDecimals(value, this.DPrecision);
    return value;
  }

  private formatInputDM(value: string, isLat: boolean) {
    let degPosition = (isLat) ? 2 : 1;
    // exclude all char but digits, dots, degrees
    value = value.replace(/[^0-9\.°]*/g, '');
    // keep only the first degree char
    value = this.keepOnlyFirstChar(value, this.deg);
    if (value.indexOf(this.deg) === 0) {
      value = "0" + value;
    }
    // keep only the firt dot char
    value = this.keepOnlyFirstChar(value, this.dot);
    // put the chars in correct order or remove it
    value = this.sanitizeCharSequence(value, [this.deg, this.dot]);
    value = this.putSymbolInRightPosition(value, this.deg, degPosition);
    let dotPosition = (value.indexOf(this.deg) === -1) ? degPosition + 3 : value.indexOf(this.deg) + 3;
    value = this.putSymbolInRightPosition(value, this.dot, dotPosition);
    value = this.manageZerosBeforeDot(value, value.indexOf(this.deg));
    value = this.truncateDecimals(value, this.MPrecision);
    return value;
  }

  private formatInputDMS(value: string, isLat: boolean) {
    let degPosition = (isLat) ? 2 : 1;
    // exclude all char but digits, dots, degrees and seconds
    value = value.replace(/[^0-9\.°']*/g, '');
    // keep only the first degree char
    value = this.keepOnlyFirstChar(value, this.deg);
    if (value.indexOf(this.deg) === 0) {
      value = "0" + value;
    }
    // keep only the firt dot char
    value = this.keepOnlyFirstChar(value, this.dot);
    // keep only the firt quote char
    value = this.keepOnlyFirstChar(value, this.quote);
    // put the chars in correct order or remove it
    value = this.sanitizeCharSequence(value, [this.deg, this.quote, this.dot]);
    value = this.putSymbolInRightPosition(value, this.deg, degPosition);
    let quotePosition = (value.indexOf(this.deg) === -1) ? degPosition + 3 : value.indexOf(this.deg) + 3;
    value = this.putSymbolInRightPosition(value, this.quote, quotePosition);
    let dotPosition = (value.indexOf(this.quote) === -1) ? quotePosition + 3 : value.indexOf(this.quote) + 3;
    value = this.putSymbolInRightPosition(value, this.dot, dotPosition);
    value = this.manageZerosBeforeDot(value, value.indexOf(this.quote));
    value = this.truncateDecimals(value, this.SPrecision);
    return value;
  }

  formatInputDecimal(value: string) {
    value = value.replace(/[^0-9\.\-]*/g, '');
    value = this.acceptCharOnlyAtPosition(value, this.minus, 0);
    value = this.keepOnlyFirstChar(value, this.dot);
    value = this.manageZerosBeforeDot(value);
    value = this.truncateDecimals(value, 3);
    return value;
  }

  parseCoord(coord: string, unit: string): Decimal[] {
    let output: Decimal[] = [];
    switch (unit) {
      case 'D':
        output = this.parseD(coord);
        break;
      case 'DM':
        output = this.parseDM(coord);
        break;
      case 'DMS':
        output = this.parseDMS(coord);
        break;
      default:
        output = [new Decimal(coord)];
    }
    return output;
  }

  private parseD(coord: string) {
    return [new Decimal(coord)];
  }

  private parseDM(coord: string) {
    let degrees = new Decimal(coord.slice(0, coord.indexOf('°')));
    let minutes = new Decimal(coord.slice(coord.indexOf('°') + 1));
    return [degrees.floor(), minutes];
  }

  private parseDMS(coord: string) {
    let degrees = new Decimal(coord.slice(0, coord.indexOf('°')));
    let minutesSeconds = coord.slice(coord.indexOf('°') + 1);
    let minutes = new Decimal(minutesSeconds.slice(0, minutesSeconds.indexOf(this.quote)));
    let seconds = new Decimal(minutesSeconds.slice(minutesSeconds.indexOf(this.quote) + 1));
    return [degrees.floor(), minutes.floor(), seconds];
  }

  coordAsString(coord: Decimal[], degreeType: string) {
    let output: string = '';
    switch (degreeType) {
      case 'D':
        output = this.DAsString(coord);
        break;
      case 'DM':
        output = this.DMAsString(coord);
        break;
      case 'DMS':
        output = this.DMSAsString(coord);
        break;
      default:
        output = this.DecimalAsString(coord);
    }
    return output;
  }

  private DecimalAsString(coord: Decimal[]): string {
    const dec = this.toRoundedString(coord[0], this.DefPrecision);
    return dec;
  }

  private DAsString(coord: Decimal[]) {
    const D = this.toRoundedString(coord[0], this.DPrecision);
    return D;
  }

  private DMAsString(coord: Decimal[]) {
    const D = this.toRoundedString(coord[0]);
    const M = this.toRoundedString(coord[1], this.MPrecision);
    return (D + this.deg + M);
  }

  private DMSAsString(coord: Decimal[]) {
    const D = this.toRoundedString(coord[0]);
    const M = this.toRoundedString(coord[1]);
    const S = this.toRoundedString(coord[2], this.SPrecision);
    return (D + this.deg + M + this.quote + S);
  }

  toRoundedString(num: Decimal, decimalPlaces = 0) {
    return num.toDecimalPlaces(decimalPlaces).toFixed(decimalPlaces);
  }

  convert(coord: Decimal[], from: string, to: string) {
    if (from == to) {
      return coord;
    }
    let output: Decimal[] = coord;
    switch (from) {
      case 'D':
        if (to === 'DM') {
          output = this.DToDM(coord);
        } else if (to === 'DMS') {
          output = this.DToDMS(coord);
        }
        break;
      case 'DM':
        if (to === 'D') {
          output = this.DMToD(coord);
        } else if (to === 'DMS') {
          output = this.DMToDMS(coord);
        }
        break;
      case 'DMS':
        if (to === 'D') {
          output = this.DMSToD(coord);
        } else if (to === 'DM') {
          output = this.DMSToDM(coord);
        }
        break;
    }
    return output;
  }

  private DToDM(val: Decimal[]) {
    const d = val[0].floor();
    const m = val[0].minus(d).times(60);
    return [d, m];
  }

  private DToDMS(val: Decimal[]) {
    const dm = this.DToDM(val);
    const d = dm[0];
    const m = dm[1].floor();
    const s = dm[1].minus(m).times(60);
    return [d, m, s];
  }

  private DMToD(val: Decimal[]) {
    const d = val[0].plus(val[1].dividedBy(60));
    return [d];
  }

  private DMToDMS(val: Decimal[]) {
    const d = val[0];
    const m = val[1].floor();
    const s = val[1].minus(m).times(60);
    return [d, m, s];
  }

  private DMSToD(val: Decimal[]) {
    const d = val[0].plus(val[1].dividedBy(60)).plus(val[2].dividedBy(3600));
    return [d];
  }

  private DMSToDM(val: Decimal[]) {
    const d = val[0];
    const m = val[1].plus(val[2].dividedBy(60));
    return [d, m];
  }

  private putSymbolInRightPosition(value: string, character: string, position: number) {
    if (value.length > position) {
      const charInVal = value.indexOf(character);
      if (charInVal === -1) {
        value = this.insertCharAtPos(value, character, position);
      } else if (charInVal > position) {
        value = value.replace(character, "");
        value = this.insertCharAtPos(value, character, position);
      }
    }
    return value;
  }

  private manageZerosBeforeDot(value: string, previousSeparator: number = -1) {
    const previousGroup = value.slice(0, previousSeparator + 1);
    let group = value.slice(previousSeparator + 1);
    // trim zeros
    //group = group.replace(/^0+(\d+)\./, '$1\.');
    group = group.replace(/^\.+/, '0\.');
    return previousGroup + group;
  }

  private truncateDecimals(value: string, decimals: number) {
    const dotInVal = value.indexOf(this.dot);
    if (dotInVal !== -1) {
      value = value.substring(0, dotInVal + decimals + 1);
    }
    return value;
  }

  private keepOnlyFirstChar(value: string, character: string) {
    let output = value;
    if (value.indexOf(character) != -1) {
      const beforeFirstChar = value.slice(0, value.indexOf(character));
      let afterFirstChar = value.slice(value.indexOf(character) + 1);
      const regex = (character === this.dot) ? new RegExp("\\.", "g") : new RegExp(character, "g");
      afterFirstChar = afterFirstChar.replace(regex, '');
      output = beforeFirstChar + character + afterFirstChar;
    }
    return output;
  }

  private insertCharAtPos(str: string, ch: string, pos: number) {
    return str.slice(0, pos) + ch + str.slice(pos);
  }

  private acceptCharOnlyAtPosition(str: string, ch: string, pos: number) {
    let output = str;
    if (str.indexOf(ch) !== -1) {
      output = str.replace(new RegExp(ch, "g"), '');
      if (str.indexOf(ch) === pos) {
        output = output.slice(0, pos) + "-" + output.slice(pos);
      }
    }
    return output;
  }

  private sanitizeCharSequence(value: string, chars: string[]) {
    let output = "";
    let ch = 0;
    let charToFind = chars[ch];
    for (var i = 0; i < value.length; i++) {
      let curChar = value.charAt(i);
      if (curChar == charToFind) {
        output += curChar;
        ch += 1;
        charToFind = chars[ch];
      } else {
        output += curChar.replace(/[^0-9]*/g, '');
      }
    }
    return output;
  }

}
