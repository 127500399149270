import { Component, OnInit } from '@angular/core';
import { TransformationConfig } from 'src/app/models/TransformationConfig';

@Component({
  selector: 'app-geoid-extraction',
  templateUrl: './geoid-extraction.component.html',
  styleUrls: ['./geoid-extraction.component.scss']
})
export class GeoidExtractionComponent implements OnInit {

  config: TransformationConfig = {
    type: 'N',
    datums: ['ETRS89'],
    ellipsoids: ['GRS80'],
    projectionTypes: ['NONE'],
    coordinateTypes: ['GEOGRAPHIC'],
    heights: ['N03', 'N18'],
    iDatumDefault: 'ETRS89',
    oDatumDefault: 'ETRS89',
    iProjDefault: 'NONE',
    iCoordTypeDefault: 'GEOGRAPHIC',
    oProjDefault: 'NONE',
    oCoordTypeDefault: 'GEOGRAPHIC',
  };

  constructor() { }

  ngOnInit(): void {
  }

}
