import { Component, OnInit } from '@angular/core';
import { TransformationConfig } from 'src/app/models/TransformationConfig';
//import { TransformatonConfig } from 'src/app/models/TransformatonConfig';

@Component({
  selector: 'app-height',
  templateUrl: './height.component.html',
  styleUrls: ['./height.component.scss']
})
export class HeightComponent implements OnInit {

  config: TransformationConfig = {
    type: 'HEIGHT',
    datums: ['ETRS89', 'WGS84'],
    ellipsoids: ['GRS80', 'WGS84'],
    projectionTypes: ['NONE', 'LAMBERT_2008'],
    coordinateTypes: ['GEOGRAPHIC', 'PLANE'],
    heights: ['EVRF2000', 'EVRF2007', 'EVRF2019', 'EGM1996', 'EGM2008'],
    iDatumDefault: 'ETRS89',
    oDatumDefault: 'WGS84',
    iProjDefault: 'NONE',
    iCoordTypeDefault: 'GEOGRAPHIC',
    oProjDefault: 'NONE',
    oCoordTypeDefault: 'GEOGRAPHIC',
  };

  constructor() { }

  ngOnInit(): void {
  }

}
