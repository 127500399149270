import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiErrorDocComponent } from './api-error-doc.component';
import { ApiErrorDocItemComponent } from './api-error-doc-item/api-error-doc-item.component';

@NgModule({
        declarations: [
                ApiErrorDocComponent,
                ApiErrorDocItemComponent
        ],
        imports: [
                CommonModule,
        ],
        exports: [
                ApiErrorDocComponent
        ]
})
export class ApiErrorDocModule { }
