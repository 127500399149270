import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoordinateComponent } from './components/coordinate/coordinate.component';
import { HeightComponent } from './components/height/height.component';
import { GeoidExtractionComponent } from './components/geoid-extraction/geoid-extraction.component';
import { ErrorComponent } from './components/error/error.component';
import { ApiErrorDocComponent } from './components/api-error-doc/api-error-doc.component';
import { ApiErrorDocItemComponent } from './components/api-error-doc/api-error-doc-item/api-error-doc-item.component';
import { HelpComponent } from './components/help/help.component';

const routes: Routes = [
  { path: '', redirectTo: '/coordinate', pathMatch: 'full' },
  { path: 'coordinate', component: CoordinateComponent },
  { path: 'height', component: HeightComponent },
  { path: 'geoidExtraction', component: GeoidExtractionComponent },
  { path: 'help', component: HelpComponent },
  {
    path: 'refData',
    children: [
      {
        path: 'errorCodeList',
        component: ApiErrorDocComponent
      },
      {
        path: 'errorCodeList/:id',
        component: ApiErrorDocItemComponent
      }
    ]
  },
  { path: '**', pathMatch: 'full', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
