<form [formGroup]="coordinatesForm" (ngSubmit)="onSubmitform()">

    <ng-container *ngIf="config.type=='COORDINATE'">
        <!-- from-to panel -->
        <div class="panel">
            <div>
                <span class="column-title">FROM</span>
                <span class="column-title">TO</span>
            </div>

            <div class="subpanel">
                <ng-container [ngTemplateOutlet]="datumTemplate"></ng-container>
                <div class="subpanel-row">
                    <div class="subpanel-column">
                        <mat-form-field>
                            <mat-label>ellipsoid</mat-label>
                            <input matInput name="iEllipsoid" formControlName="iEllipsoid" readonly />
                        </mat-form-field>
                    </div>
                    <div class="subpanel-column">
                        <mat-form-field>
                            <mat-label> </mat-label>
                            <input matInput name="oEllipsoid" formControlName="oEllipsoid" readonly />
                        </mat-form-field>
                    </div>
                </div>
                <div class="subpanel-row" *ngIf="isIcoordDateSet">
                    <ng-container [ngTemplateOutlet]="inputDateTemplate"></ng-container>
                </div>
            </div>

            <div class="subpanel">
                <ng-container [ngTemplateOutlet]="projectionTemplate"></ng-container>
                <div class="subpanel-row">
                    <div class="subpanel-column">
                        <mat-form-field>
                            <mat-label>coordinates type</mat-label>
                            <mat-select name="iCoordType" formControlName="iCoordType">
                                <mat-option *ngFor="let ct of iCoordinateTypes" [value]="ct">
                                    {{ ct }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="subpanel-column">
                        <mat-form-field>
                            <mat-select name="oCoordType" formControlName="oCoordType">
                                <mat-option *ngFor="let ct of oCoordinateTypes" [value]="ct">
                                    {{ ct }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <!-- input-output panel -->
        <ng-container [ngTemplateOutlet]="inputOutputTemplate"></ng-container>

    </ng-container>

    <ng-container *ngIf="config.type=='HEIGHT'">
        <!-- from-to panel -->
        <div class="panel">
            <div>
                <span class="column-title">FROM</span>
                <span class="column-title">TO</span>
            </div>
            <div class="subpanel">
                <ng-container [ngTemplateOutlet]="datumTemplate"></ng-container>
                <div class="subpanel-row">
                    <ng-container *ngIf="isIcoordDateSet; else noDateBlock"
                        [ngTemplateOutlet]="inputDateTemplate"></ng-container>
                    <ng-template #noDateBlock>
                        <div class="subpanel-column"></div>
                    </ng-template>
                    <div class="subpanel-column">
                        <mat-form-field>
                            <mat-label>height</mat-label>
                            <mat-select multiple #h name="oHeights" formControlName="oHeights">
                                <mat-select-trigger>
                                    {{h.value ? h.value[0] : ''}}
                                    <span *ngIf="h.value?.length > 1">
                                        +{{h.value.length - 1}}
                                    </span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let height of config.heights" [value]="height">
                                    {{ height}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="subpanel">
                <ng-container [ngTemplateOutlet]="projectionTemplate"></ng-container>
            </div>
        </div>
        <!-- input-output panel -->
        <ng-container [ngTemplateOutlet]="inputOutputTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="config.type=='N'">
        <!-- no from-to panel -->
        <!-- input-output panel -->
        <ng-container [ngTemplateOutlet]="inputOutputTemplate"></ng-container>
    </ng-container>

    <!-- submit panel -->
    <div>
        <p>
            <button mat-flat-button color="accent" type="submit" class="submit">convert</button>
        </p>
    </div>
    <div id="messageWrapper">
        <div *ngIf=(success) class="success">
            <p>{{ success }}</p>
        </div>
        <div *ngIf=(warning) class="warning">
            <p>{{ warning }}</p>
        </div>
        <div *ngIf=(error) class="error" style="white-space: pre-line">
            <p>{{ error }}</p>
        </div>
    </div>

</form>

<!-- templates -->

<ng-template #datumTemplate let-form="datumTemplate">
    <div class="subpanel-row" [formGroup]="coordinatesForm">
        <div class="subpanel-column">
            <mat-form-field>
                <mat-label>datum</mat-label>
                <mat-select name="iDatum" formControlName="iDatum">
                    <mat-option *ngFor="let datum of config.datums" [value]="datum">
                        {{ datum }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="subpanel-column">
            <mat-form-field>
                <mat-select name="oDatum" formControlName="oDatum">
                    <mat-option *ngFor="let datum of config.datums" [value]="datum">
                        {{ datum }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #inputDateTemplate let-form="inputDateTemplate">
    <div class="subpanel-column" [formGroup]="coordinatesForm">
        <mat-form-field>
            <mat-label>Transformation validity date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="iCoordDate" readonly (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #projectionTemplate let-form="projectionTemplate">
    <div class="subpanel-row" [formGroup]="coordinatesForm">
        <div class="subpanel-column">
            <mat-form-field>
                <mat-label>projection </mat-label>
                <mat-select name="iProj" formControlName="iProj">
                    <mat-option *ngFor="let pt of iProjectionTypes" [value]="pt">
                        {{ pt }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="subpanel-column">
            <mat-form-field>
                <mat-select name="oProj" formControlName="oProj">
                    <mat-option *ngFor="let pt of oProjectionTypes" [value]="pt">
                        {{ pt }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #inputOutputTemplate let-form="inputOutputTemplate">
    <div class="panel" [formGroup]="coordinatesForm">
        <div>
            <span class="column-title">INPUT</span>
            <span class="column-title">OUTPUT</span>
        </div>
        <div class="subpanel">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" #tabGroup
                (selectedTabChange)="onInputTypeChange($event)">
                <mat-tab label="Text">
                    <br>
                    <div class="subpanel-row">
                        <div class="subpanel-column">
                            <!-- input coordinates -->
                            <app-coordinate-data #coordinateInputComponent [fg]="coordinatesForm"></app-coordinate-data>
                        </div>
                        <div class="subpanel-column">
                            <!-- output coordinates -->
                            <app-coordinate-data *ngIf="config.type=='COORDINATE'" #coordinateOutputComponent
                                [fg]="coordinatesForm" [isInput]="false"></app-coordinate-data>
                            <!-- output heights -->
                            <app-height-data *ngIf="config.type=='HEIGHT'" #heightOutputComponent></app-height-data>
                            <!-- geoid-extraction -->
                            <app-height-data *ngIf="config.type=='N'" #heightOutputComponent></app-height-data>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="File">
                    <br>
                    <div class="subpanel-row">
                        <div class="subpanel-column">
                            <div *ngIf="coordinatesForm.value.iCoordType==='GEOGRAPHIC'">
                                <mat-radio-group name="fileDegreeType" formControlName="iFileDegreeType"
                                    (change)="onFileDegreeTypeChange($event)">
                                    <mat-radio-button class="radio" value="D" checked="true"><span
                                            class="radio-spaced">D</span></mat-radio-button>
                                    <mat-radio-button class="radio" value="DM"><span
                                            class="radio-spaced">DM</span></mat-radio-button>
                                    <mat-radio-button class="radio" value="DMS"><span
                                            class="radio-spaced">DMS</span></mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <br>
                            <mat-form-field>
                                <mat-label>file format</mat-label>
                                <mat-select name="iFileFormat" formControlName="iFileFormat">
                                    <mat-option *ngFor="let ff of fileFormats; let i = index" [value]="i">
                                        {{ ff }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div [ngClass]="isInputFileSet ? 'filezone' : 'dropzone'" dropZone
                                (fileDropped)="onFileDropped($event)">
                                <div>
                                    <input type="file" #fileDropRef id="fileDropRef" multiple
                                        (change)="onFileSelected($event)" />
                                    <div>Drag and drop input file</div>
                                    <div>OR</div>
                                    <br>
                                    <div><label for="fileDropRef" class="file-link">Browse...</label></div>
                                </div>
                            </div>
                            <p *ngIf="isFileGeoJson" class="note">Note: The GeoJSON standard (RFC 7946)
                                officially only
                                supports the WGS84 reference system, with longitude and latitude units in decimal
                                degrees. Conversion to other formats is offered here at the user's discretion.</p>
                            <div>
                                <span>
                                    {{ iFileWrapper.file.name }}
                                </span>
                                <app-progress-bar [progress]="iFileWrapper.progress"></app-progress-bar>
                                <div *ngIf="isInputFileSet">
                                    <mat-error *ngIf="iFile?.errors?.['required']">Required</mat-error>
                                    <mat-error *ngIf="iFile?.errors?.['invalidFileExt']">Invalid file
                                        extension</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="subpanel-column outputfile-wrapper"
                            [ngStyle]="{'padding-top': coordinatesForm.value.iCoordType==='GEOGRAPHIC' ? '105px' : '85px'}">
                            <div *ngIf="isWaiting" class="filezone">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div *ngIf="isFileProcessed" class="filezone">
                                <div>
                                    <div>Click to fetch output file</div>
                                    <button type="button" mat-icon-button aria-label="fetch file" alt="fetch file"
                                        class="file-link" (click)="fetchOutputFile()">
                                        <mat-icon>attachment</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ng-template>