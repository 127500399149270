import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'coordinateTransformations';

  navLinks = [
    {
      label: 'Coordinate',
      route: 'coordinate',
    },
    {
      label: 'Height',
      route: 'height',
    },
    {
      label: 'Geoid extraction',
      route: 'geoidExtraction',
    }
  ];
  activeLink = this.navLinks[0].route;
  isWebAppPage = true;

  ngOnInit(): void {
    const location = window.location.href;
    if (location.includes("refData")) {
      this.isWebAppPage = false;
    } else if (location.includes("help")) {
      this.isWebAppPage = false;
    }
  }

  contact() {
    const encEmail = "Y2NvbnZlcnRAbmdpLmJl";
    window.location.assign("mailto:".concat(atob(encEmail)));
  }

}
