import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { ConversionRequest } from '../models/ConversionRequest';
import { ConversionResponse } from '../models/ConversionResponse';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  apiPath = environment.apiPath;

  // services endpoints
  convertCoordinatesUrl: string = this.apiPath + "/convertCoordinates";
  convertCoordinateFileUrl: string = this.apiPath + "/convertCoordinateFile";
  convertHeightsUrl: string = this.apiPath + "/convertHeights";
  convertHeightFileUrl: string = this.apiPath + "/convertHeightFile";
  calculateNUrl: string = this.apiPath + "/calculateN";
  calculateNFileUrl: string = this.apiPath + "/calculateNFile";

  // service only needed when the download portal is used
  fileDownloadServiceUrl: string = this.apiPath + "/downloadFile";

  private coordinateResponseSubject: Subject<ConversionResponse>;
  readonly $coordinateResponse: Observable<ConversionResponse>;

  private fileResponseSubject: Subject<any>;
  readonly $fileResponse: Observable<any>;

  private errorSubject: Subject<any>;
  readonly $error: Observable<any>;

  constructor(private http: HttpClient) {
    this.coordinateResponseSubject = new Subject<ConversionResponse>();
    this.$coordinateResponse = this.coordinateResponseSubject.asObservable();

    this.fileResponseSubject = new Subject<any>();
    this.$fileResponse = this.fileResponseSubject.asObservable();

    this.errorSubject = new Subject<any>();
    this.$error = this.errorSubject.asObservable();
  }

  sendDataRequest(requestType: string, request: ConversionRequest) {
    let dataServiceUrl = "";
    switch (requestType) {
      case "COORDINATE":
        dataServiceUrl = this.convertCoordinatesUrl;
        break;
      case "HEIGHT":
        dataServiceUrl = this.convertHeightsUrl;
        break;
      case "N":
        dataServiceUrl = this.calculateNUrl;
        break;
    }
    console.log("sent : " + JSON.stringify(request));
    const headers = new HttpHeaders()
      .append(
        'Content-Type',
        'application/json'
      );
    return this.http.post<any>(dataServiceUrl, request, {
      headers: headers,
    }).subscribe((res: any) => {
      const cRes = new ConversionResponse('success', res.oCoords, res.oCoordsAsString, res.oCoordsAsArray);
      this.coordinateResponseSubject.next(cRes);
    }, error => {
      this.handleJsonErrorResponse(error);
    });

  }

  // request to convert file directly
  sendConvertFileRequest(requestType: string, formData: FormData, fileName: string) {
    let fileServiceUrl = "";
    switch (requestType) {
      case "COORDINATE":
        fileServiceUrl = this.convertCoordinateFileUrl;
        break;
      case "HEIGHT":
        fileServiceUrl = this.convertHeightFileUrl;
        break;
      case "N":
        fileServiceUrl = this.calculateNFileUrl;
        break;
    }
    const token = Math.random().toString(36).substring(2);
    const headers = new HttpHeaders().set('authorization', 'Bearer ' + token);
    this.http.post(fileServiceUrl, formData, { headers, responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        this.fileResponseSubject.next(new Blob(binaryData, { type: dataType }));
      }, error => {
        this.handleJsonErrorResponse(error);
      }
    )
  }

  private handleJsonErrorResponse(error: any) {
    let errorMessage = "The request failed";
    if (error.error) {
      if (error.error instanceof Blob) {
        error.error.text().then((text: any) => {
          try {
            this.errorSubject.next(JSON.parse(text).detail);
          } catch (e) {
            this.errorSubject.next(errorMessage);
          }
        });
      } else if (error.error.detail !== null) {
        this.errorSubject.next(error.error.detail);
      }
    } else {
      this.errorSubject.next(errorMessage);
    }
  }

}
