<div class="custom-container ngi-dark-blue-theme-light">

  <header>
    <div class="ign-logo"><a [routerLink]="['']"><img src="assets/images/ngi-logo.svg" height="150px"></a></div>
    <div class="app-logo">
      <div><img src="assets/images/cConvert-logo.svg" height="40px"></div>
      <div class="baseline">Coordinate transformations</div>
    </div>
    <div class="help_container"><a routerLink="/help" class="help"><mat-icon>help</mat-icon></a></div>
  </header>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-align-tabs="start" *ngIf="isWebAppPage">
    <a mat-tab-link *ngFor=" let link of navLinks" [routerLink]="link.route" routerLinkActive #rla="routerLinkActive"
      [active]="rla.isActive"> {{link.label}} </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>

  <footer>
    <p>
      <a href="assets/pdf/20230807_gebruiksvoorwaarden_NL.pdf">Algemene gebruiksvoorwaarden</a> -
      <a href="assets/pdf/20230807_gebruiksvoorwaarden_FR.pdf">Conditions générales d’utilisation</a> -
      <a id="contact" (click)="contact()">Contact</a>
    </p>
  </footer>

</div>