export class ApiErrorDescription {

        constructor(
                public id: string,
                public title: string,
                public status: string,
                public description: string,
        ) {
        }

}