import { Component, OnInit } from '@angular/core';
import { TransformationConfig } from 'src/app/models/TransformationConfig';

@Component({
  selector: 'app-coordinate',
  templateUrl: './coordinate.component.html',
  styleUrls: ['./coordinate.component.scss']
})
export class CoordinateComponent implements OnInit {

  config: TransformationConfig = {
    type: 'COORDINATE',
    datums: ['BD72', 'ED50', 'ETRS89', 'WGS84'],
    ellipsoids: ['HAYFORD1924', 'GRS80', 'WGS84'],
    projectionTypes: ['LAMBERT_2008', 'LAMBERT_1972', 'NONE', 'UTM31', 'UTM32', 'ETRS_LCC', 'ETRS_LAEA'],
    coordinateTypes: ['GEOGRAPHIC', 'GEOCENTRIC', 'PLANE'],
    heights: [],
    iDatumDefault: 'BD72',
    oDatumDefault: 'ETRS89',
    iProjDefault: 'LAMBERT_1972',
    iCoordTypeDefault: 'PLANE',
    oProjDefault: 'LAMBERT_2008',
    oCoordTypeDefault: 'PLANE'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
