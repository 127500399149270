import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { ApiErrorDescription } from 'src/app/models/ApiErrorDescription';
import { ApiErrorDocService } from 'src/app/services/api-error-doc.service';

@Component({
  selector: 'app-api-error-doc',
  templateUrl: './api-error-doc.component.html',
  styleUrls: ['./api-error-doc.component.scss']
})
export class ApiErrorDocComponent implements OnDestroy {

  errorDescriptions: ApiErrorDescription[] | undefined;
  private subscriptions: Subscription;

  constructor(private router: Router, private apiErrorDocService: ApiErrorDocService) {
    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.apiErrorDocService.$dataLoaded.subscribe((dataLoaded: boolean) => {
        const eDescList = this.apiErrorDocService.listErrorDescriptions();
        if (eDescList) {
          this.errorDescriptions = eDescList;
        } else {
          this.navigateToErrorPage("error reference not found");
        }
      }, error => {
        this.navigateToErrorPage(error);
      })
    );
  }

  private navigateToErrorPage(error: any) {
    console.log(error);
    this.router.navigate(['/error']);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
