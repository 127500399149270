<div class="height-series" *ngFor="let serie of heightSeries ;let i = index;">
        <fieldset class="coordEntry">
                <legend>coordinates {{i+1}}</legend>
                <div *ngFor="let heightView of serie">
                        <mat-form-field>
                                <mat-label>{{ heightView.label }}</mat-label>
                                <input matInput type="text" [value]="heightView.value">
                        </mat-form-field>
                </div>
        </fieldset>
</div>
