import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  apiPath = environment.apiPath;

  constructor() { }

  ngOnInit(): void {
  }

  contact() {
    const encEmail = "Y2NvbnZlcnRAbmdpLmJl";
    window.location.assign("mailto:".concat(atob(encEmail)));
  }

}
