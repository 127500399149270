import { Directive, EventEmitter, HostBinding, HostListener, Input, Output, ElementRef } from '@angular/core';

@Directive({
  selector: '[dropZone]'
})
export class DropzoneDirective {

  @HostBinding('class.fileover') fileOver: boolean = false;
  @Output() fileDropped = new EventEmitter<any>();

  constructor(public eleRef: ElementRef) {
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    console.log('dragover');
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    console.log('dragleave');
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: any) {
    console.log('drop');
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer?.files;
    if (files && files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

}