import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpComponent } from './help.component';

@NgModule({
        declarations: [
                HelpComponent
        ],
        imports: [
                CommonModule,
        ],
        exports: [
                HelpComponent
        ]
})
export class HelpModule { }