import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, AbstractControl, FormControl, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ConversionService } from 'src/app/services/conversion.service';
import { ConversionRequest } from 'src/app/models/ConversionRequest';
import { ConversionResponse } from 'src/app/models/ConversionResponse';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CoordinateDataComponent } from './coordinate-data/coordinate-data.component';
import { Subscription } from 'rxjs';
import { TransformationConfig } from 'src/app/models/TransformationConfig';
import { HeightDataComponent } from './height-data/height-data.component';

export const DDMMYYYY = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export type FileWrapper = {
  file: File,
  progress: number
}

export function fileExtensionValidator(file: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = true;
    if (control.value) {
      const fileExt = control.value.name.split('.').pop();
      file.split(',').forEach(ext => {
        if (ext.trim() == fileExt) {
          forbidden = false;
        }
      });
    }
    return forbidden ? { 'invalidFileExt': true } : null;
  };
}

@Component({
  selector: 'app-transformation-form',
  templateUrl: './transformation-form.component.html',
  styleUrls: ['./transformation-form.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DDMMYYYY }
  ]
})
export class TransformationFormComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;

  @Input() config: TransformationConfig = {
    type: '',
    datums: [],
    ellipsoids: [],
    projectionTypes: [],
    coordinateTypes: [],
    heights: [],
    iDatumDefault: '',
    oDatumDefault: '',
    iProjDefault: '',
    iCoordTypeDefault: '',
    oProjDefault: '',
    oCoordTypeDefault: '',
  };

  @ViewChild('coordinateInputComponent') coordinateInputComponent!: CoordinateDataComponent;
  @ViewChild('coordinateOutputComponent') coordinateOutputComponent!: CoordinateDataComponent;
  @ViewChild('heightOutputComponent') heightOutputComponent!: HeightDataComponent;
  @ViewChild('messageWrapper') messageWrapper: ElementRef | undefined;

  inputTypes: string[] = ['Text', 'File'];
  fileFormats: string[] = ['csv (x,y[,z])', 'csv (id,x,y[,z])', 'geoJSON'];
  iProjectionTypes: string[] = [];
  oProjectionTypes: string[] = [];
  iCoordinateTypes: string[] = [];
  oCoordinateTypes: string[] = [];

  iCoordType: string = '';
  iProj: string = '';
  iCoords: string[][] = [];

  fileValidator: any[] = [Validators.required, fileExtensionValidator('csv,json')];

  outputFileData: Blob = new Blob();

  MSG_SUCCESS = 'The request succeeded.';
  MSG_ERROR = 'The request failed.';

  iFileWrapper: FileWrapper = {
    file: new File([], ''),
    progress: 0
  };
  isFileSelected: boolean = false; // is "File" selected instead of "Text"
  isInputFileSet = false; // is the file is dropped or selected
  isFileProcessed: boolean = false; // is the file send to backend
  isWaiting: boolean = false;
  downloadURL: string = '';
  copyURL: string = '';
  success: string = '';
  warning: string = '';
  error: string = '';
  isIcoordDateSet = false;
  isFileGeoJson = false;

  coordinatesForm: FormGroup;

  constructor(private _formBuilder: FormBuilder, private conversionService: ConversionService, private changeDetector: ChangeDetectorRef) {

    this.coordinatesForm = this._formBuilder.group({
      iDatum: '',
      iEllipsoid: '',
      iProj: '',
      iCoordType: '',
      iDegreeType: '',
      iCoordDate: new Date(),
      iFileDegreeType: new FormControl({ value: '', disabled: true }),

      oDatum: '',
      oEllipsoid: '',
      oProj: '',
      oCoordType: '',
      oDegreeType: '',

      iCoordsArray: this._formBuilder.array([]),
      iFile: new FormControl(undefined, []),
      iFileFormat: '',
      oCoordsArray: this._formBuilder.array([]),
      oHeights: [],
    });

    this.subscriptions = new Subscription();

    this.subscriptions.add(
      this.conversionService.$coordinateResponse.subscribe((cRes: ConversionResponse) => {
        this.isWaiting = false;
        if (cRes.status === 'success') {
          if (this.coordinateOutputComponent) {
            this.coordinateOutputComponent.handleReceivedCoordinates(cRes.oCoords);
            this.displaySuccess();
          }
          if (this.heightOutputComponent) {
            const requestedheights: string[] = this.coordinatesForm.get('oHeights')?.value;
            this.heightOutputComponent.handleReceivedHeights(cRes.oCoords, requestedheights);
            if (this.heightOutputComponent.isMissingHeights) {
              this.displayWarning("The request succeeded but some requested heights are missing");
            } else {
              this.displaySuccess();
            }
          }
        } else {
          this.isWaiting = false;
          this.displayError();
        }
      }, error => {
        this.isWaiting = false;
        this.displayError(error);
      })
    );

    this.subscriptions.add(
      this.conversionService.$fileResponse.subscribe((data: Blob) => {
        this.isWaiting = false;
        this.displaySuccess("The file has been converted successfully.");
        this.isFileProcessed = true;
        this.outputFileData = data;
      }, error => {
        this.isWaiting = false;
        this.displayError(error);
      })
    );

    this.subscriptions.add(
      this.conversionService.$error.subscribe((error: any) => {
        this.isWaiting = false;
        this.displayError(error);
      }, error => {
        this.isWaiting = false;
        this.displayError(error);
      })
    );

  }

  //get iCoordAsString() { return this.coordinatesForm.get('iCoordAsString'); }
  get iFile() { return this.coordinatesForm.get('iFile'); }
  getCoordinateInputComponent() { return this.coordinateInputComponent; }
  getCoordinateOutputComponent() { return this.coordinateOutputComponent; }
  getHeightOutputComponent() { return this.heightOutputComponent; }

  ngOnInit(): void {
    this.onFormChanges();
    // initialize values
    this.setFormValue('iDatum', this.config.iDatumDefault);
    this.setFormValue('oDatum', this.config.oDatumDefault);
    this.setEllipsoid('iEllipsoid', this.coordinatesForm.value['iDatum']);
    this.setEllipsoid('oEllipsoid', this.coordinatesForm.value['oDatum']);
    this.setProjections('iProj', this.coordinatesForm.value['iDatum']);
    this.setFormValue('iProj', this.config.iProjDefault);
    this.setProjections('oProj', this.coordinatesForm.value['oDatum']);
    this.setFormValue('oProj', this.config.oProjDefault);
    this.setCoordinateTypes('iCoordType', this.coordinatesForm.value['iProj']);
    this.setFormValue('iCoordType', this.config.iCoordTypeDefault);
    this.setCoordinateTypes('oCoordType', this.coordinatesForm.value['oProj']);
    this.setFormValue('oCoordType', this.config.oCoordTypeDefault);
    this.setFormValue('oHeights', this.config.heights);
    this.setFormValue('iFileFormat', 0);
    this.setFormValue('iFileDegreeType', 'D');
    this.setIcoordDate();
  }

  /* this is required to avoid error ng0100 'Expression has changed after it was checked' when calling 'handleSubmittedCoordinates' */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onInputTypeChange(tabChangeEvent: MatTabChangeEvent) {
    const inputType = this.inputTypes[tabChangeEvent.index];
    if (inputType === 'File') {
      this.isFileSelected = true;
      this.updateValidator('iFile', this.fileValidator);
    } else {
      this.isFileSelected = false;
      this.updateValidator('iFile', []);
    }
  }

  onFileDropped(files: any) {
    if (files.length > 0) {
      const file = files[0];
      this.setInputFile(file);
    }
  }

  onFileSelected(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.setInputFile(file);
    }
  }

  onFileDegreeTypeChange(event: any) {
    this.setFormValue('iFileDegreeType', event.value);
  }

  onSubmitform(): void {
    this.resetOutputs()
    const formValue = this.coordinatesForm.value;
    // send file
    if (this.isFileSelected) {
      const formData = new FormData();
      formData.append('iDatum', formValue['iDatum']);
      formData.append('iProj', this.formatForLambert(formValue['iProj']));
      formData.append('iCoordType', formValue['iCoordType']);
      formData.append('iFileFormat', formValue['iFileFormat']);
      formData.append('iFile', formValue['iFile']);
      formData.append('oDatum', formValue['oDatum']);
      formData.append('oProj', this.formatForLambert(formValue['oProj']));
      formData.append('oCoordType', formValue['oCoordType']);
      formData.append('degreeType', (formValue['iFileDegreeType']) ? (formValue['iFileDegreeType']) : 'D');
      if (this.isIcoordDateSet) {
        formData.append('date', formValue['iCoordDate'].toJSON());
      }
      this.conversionService.sendConvertFileRequest(this.config.type, formData, this.iFileWrapper.file.name);

      // send raw coordinates
    } else {
      this.iCoords = this.coordinateInputComponent.handleSubmittedCoordinates();
      if (!this.validateGeographics(this.iCoords, formValue['iCoordType'])) {
        return;
      }
      const request = new ConversionRequest(
        formValue['iDatum'],
        this.formatForLambert(formValue['iProj']),
        formValue['iCoordType'],
        formValue['oDatum'],
        this.formatForLambert(formValue['oProj']),
        formValue['oCoordType'],
        'D', // we format the output ourself
        this.iCoords,
      );
      if (this.isIcoordDateSet) {
        request.setDate(formValue['iCoordDate']);
      }
      if (!this.coordinatesForm.valid) {
        this.displayError("The entries are not valid.");
        return;
      }
      this.isWaiting = true;
      console.log('sending request : ' + request);
      this.conversionService.sendDataRequest(this.config.type, request);
    }
  }

  // progress bar
  uploadFilesSimulator(iFileWrapper: FileWrapper) {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (iFileWrapper.progress === 100) {
          clearInterval(progressInterval);
        } else {
          iFileWrapper.progress += 5;
        }
      }, 30);
    }, 500);
  }

  fetchOutputFile() {
    const outpuFileName: string = this.defineOutputFileName(this.iFileWrapper.file.name);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(this.outputFileData);
    downloadLink.setAttribute('download', outpuFileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  displaySuccess(successMessage: string = this.MSG_SUCCESS) {
    this.success = successMessage;
  }

  displayWarning(warningMessage: string) {
    this.warning = warningMessage;
  }

  displayError(errorMessage: string = this.MSG_ERROR) {
    const coordListMsgPrefix = "The following coordinates cannot be converted : ";
    if (errorMessage.startsWith(coordListMsgPrefix)) {
      errorMessage = errorMessage.replace(coordListMsgPrefix, coordListMsgPrefix + "\n\n").replace(/;/g, ';\n');
    }
    this.error = errorMessage;
  }

  private onFormChanges() {

    // iDatum
    this.coordinatesForm.get('iDatum')?.valueChanges.subscribe(iDatum => {
      this.setEllipsoid('iEllipsoid', iDatum);
      this.setProjections('iProj', iDatum);
      this.setIcoordDate();
    });

    // oDatum
    this.coordinatesForm.get('oDatum')?.valueChanges.subscribe(oDatum => {
      this.setEllipsoid('oEllipsoid', oDatum);
      this.setProjections('oProj', oDatum);
      this.setIcoordDate();
    });

    // iProj
    this.coordinatesForm.get('iProj')?.valueChanges.subscribe(iProj => {
      this.setCoordinateTypes('iCoordType', iProj);
    });

    // oProj
    this.coordinatesForm.get('oProj')?.valueChanges.subscribe(oProj => {
      this.setCoordinateTypes('oCoordType', oProj);
    });

    // iFile
    this.coordinatesForm.get('iFile')?.valueChanges.subscribe(() => {
      this.resetOutputs();
    });

    // iCoordType
    this.coordinatesForm.get('iCoordType')?.valueChanges.subscribe((newValue: string) => {
      // newValue necesssary because the value change effectively after the call.
      this.updateCoordinateComponent('input', newValue);
    });

    // oCoordType
    this.coordinatesForm.get('oCoordType')?.valueChanges.subscribe((newValue: string) => {
      // newValue necesssary because the value change effectively after the call.
      this.updateCoordinateComponent('output', newValue);
    });

    // iFileFormat
    this.coordinatesForm.get('iFileFormat')?.valueChanges.subscribe(() => {
      this.updateFileFormat();
    });

  }

  private setFormValue(field: string, value: any) {
    this.coordinatesForm.patchValue({
      [field]: value
    });
  }

  private setEllipsoid(field: string, datum: string) {
    let ellipsoid: string;
    switch (datum) {
      case 'BD72':
        ellipsoid = 'HAYFORD1924'
        break;
      case 'ED50':
        ellipsoid = 'HAYFORD1924'
        break;
      case 'ETRS89':
        ellipsoid = 'GRS80'
        break;
      case 'WGS84':
        ellipsoid = 'WGS84'
        break;
      default:
        ellipsoid = 'HAYFORD1924'
    }
    this.setFormValue(field, ellipsoid);
  }

  private fetchAvailableOptions(options: string[], configOptions: string[]) {
    let availableOptions: string[] = [];
    options.forEach(option => {
      if (configOptions.includes(option)) {
        availableOptions.push(option);
      }
    });
    return availableOptions;
  }

  private setProjections(field: string, datum: string) {
    let projections: string[];
    switch (datum) {
      case 'BD72':
        projections = this.fetchAvailableOptions(['LAMBERT_1972', 'NONE'], this.config.projectionTypes);
        break;
      case 'ED50':
        projections = this.fetchAvailableOptions(['NONE', 'UTM31', 'UTM32'], this.config.projectionTypes);
        break;
      case 'ETRS89':
        projections = this.fetchAvailableOptions(['LAMBERT_2008', 'NONE', 'ETRS_LCC', 'ETRS_LAEA', 'UTM31', 'UTM32'], this.config.projectionTypes);
        break;
      case 'WGS84':
        projections = this.fetchAvailableOptions(['NONE'], this.config.projectionTypes);
        break;
      default:
        projections = this.fetchAvailableOptions(['NONE'], this.config.projectionTypes);
    }

    if (field == 'iProj') {
      this.iProjectionTypes = projections;
    } else if (field == 'oProj') {
      this.oProjectionTypes = projections;
    } else {
      console.log('invalid field');
    }

    this.setFormValue(field, projections[0]);

  }

  private setCoordinateTypes(field: string, proj: string) {

    let coordTypes: string[];
    if (proj == 'NONE') {
      coordTypes = this.fetchAvailableOptions(['GEOGRAPHIC', 'GEOCENTRIC'], this.config.coordinateTypes);
    } else {
      coordTypes = this.fetchAvailableOptions(['PLANE'], this.config.coordinateTypes);
    }

    if (field == 'iCoordType') {
      this.iCoordinateTypes = coordTypes;
      this.updateCoordinateComponent('input');
    }
    else if (field == 'oCoordType') {
      this.oCoordinateTypes = coordTypes;
      this.updateCoordinateComponent('output');
    }
    else {
      console.log('invalid field');
    }

    this.setFormValue(field, coordTypes[0]);

  }

  private setIcoordDate() {
    if ((this.coordinatesForm.get('iDatum')?.value == 'ETRS89') && (this.coordinatesForm.get('oDatum')?.value == 'WGS84')) {
      this.isIcoordDateSet = true;
    } else if ((this.coordinatesForm.get('iDatum')?.value == 'WGS84') && (this.coordinatesForm.get('oDatum')?.value == 'ETRS89')) {
      this.isIcoordDateSet = true;
    } else {
      this.isIcoordDateSet = false;
    }
  }

  private setInputFile(file: any) {
    this.isInputFileSet = true;
    this.setFormValue('iFile', file);
    this.iFileWrapper = {
      file: file,
      progress: 0
    };
    this.uploadFilesSimulator(this.iFileWrapper);
  }

  private updateFileFormat(): void {
    if (this.coordinatesForm.get('iFileFormat')?.value === 2) {
      this.coordinatesForm.get('iFileDegreeType')?.disable();
      this.isFileGeoJson = true;
      this.setFormValue('iFileDegreeType', 'D');
    } else {
      this.coordinatesForm.get('iFileDegreeType')?.enable();
      this.isFileGeoJson = false;
    }
  }

  private updateValidator(field: string, validators: any) {
    this.coordinatesForm.controls[field].setValidators(validators);
    this.coordinatesForm.controls[field].updateValueAndValidity();
  }

  private resetOutputs() {
    this.isWaiting = false;
    this.success = '';
    this.warning = '';
    this.error = '';
    if (this.coordinateOutputComponent) {
      this.coordinateOutputComponent.reset();
    }
    if (this.heightOutputComponent) {
      this.heightOutputComponent.reset();
    }
    this.isFileProcessed = false;
    this.downloadURL = '';
  }

  private defineOutputFileName(iFileName: string) {
    let fileBase: string = "";
    let fileExt: string = "";
    const dotIndex = iFileName.lastIndexOf(".");
    if (dotIndex == -1) {
      fileBase = iFileName;
    } else {
      fileBase = iFileName.substring(0, dotIndex);
      fileExt = iFileName.substring(dotIndex);
    }
    const fileFragments = [fileBase, "converted", this.coordinatesForm.get('oDatum')?.value, this.coordinatesForm.get('oProj')?.value, this.coordinatesForm.get('oCoordType')?.value, fileExt]
    return fileFragments.join("_");
  }

  private updateCoordinateComponent(inOrout: string, newCoordType?: string) {
    const coordComponent: CoordinateDataComponent = (inOrout === 'input') ? this.coordinateInputComponent : this.coordinateOutputComponent;
    if (coordComponent) {
      coordComponent.refresh(newCoordType);
    }
  }

  private formatForLambert(val: string) {
    if (val.indexOf('LAMBERT') !== -1) {
      return 'LAMBERT';
    }
    return val;
  }

  private validateGeographics(iCoords: string[][], iCoordType: string): boolean {
    if (iCoordType !== "GEOGRAPHIC") {
      return true;
    }
    let valid = true;
    let error: string[] = [];
    iCoords.forEach((iCoord: any[]) => {
      const lambda = iCoord[0];
      if ((lambda < 0.9) || (lambda > 7.1)) {
        error.push("λ should be between 0.9 and 7.1");
        valid = false;
      }
      const phi = iCoord[1];
      if ((phi < 48.4) || (phi > 52.6)) {
        error.push("Φ should be between 48.4 and 52.6");
        valid = false;
      }
    });
    if (!valid) {
      this.displayError(error.join(', '));
    }
    return valid;
  }

}
