import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MaterialModule } from './shared/material/material.module';
import { ProgressBarModule } from './components/progress-bar/progress-bar.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { TransformationFormModule } from './components/transformation-form/transformation-form.module';
import { CoordinateModule } from './components/coordinate/coordinate.module';
import { HeightModule } from './components/height/height.module';
import { GeoidExtractionModule } from './components/geoid-extraction/geoid-extraction.module';
import { ErrorModule } from './components/error/error.module';
import { ApiErrorDocModule } from './components/api-error-doc/api-error-doc.module';
import { HelpModule } from './components/help/help.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    BrowserAnimationsModule,
    CoordinateModule,
    HeightModule,
    GeoidExtractionModule,
    TransformationFormModule,
    ProgressBarModule,
    ErrorModule,
    ApiErrorDocModule,
    HelpModule
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
