<div class="panel explanations">
        <p>Calculation of the geoid-ellipsoid separation (N) at given points.</p>
        <p>Datum: ETRS89 | ellipsoid: GRS80</p>
        <br>
        <p>Height conversion models <a href="https://www.isgeoid.polimi.it/Geoid/Europe/Belgium/belgium03_g.html"
                        target="_blank">hBG03</a> and <a href="https://doi.org/10.5880/isg.2018.003"
                        target="_blank">hBG18</a>, based on <a
                        href="https://www.isgeoid.polimi.it/Geoid/Europe/Belgium/belgium_g.html"
                        target="_blank">BG2003</a> and <a
                        href="https://www.isgeoid.polimi.it/Geoid/Europe/Netherlands/netherland18_g.html"
                        target="_blank">NLGEO2018</a> repectively. Bilinear interpolation is used.</p>
</div>
<app-transformation-form [config]="config"></app-transformation-form>