import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormattingService } from 'src/app/services/formatting.service';
import { pairwise, startWith } from "rxjs/operators";
import Decimal from 'decimal.js';

@Component({
  selector: 'app-coordinate-data',
  templateUrl: './coordinate-data.component.html',
  styleUrls: ['./coordinate-data.component.scss']
})
export class CoordinateDataComponent implements OnInit, AfterContentChecked {

  @Input() fg!: FormGroup;
  /* true if it is a input component, false if it is an output component */
  @Input() isInput: boolean = true;

  coordTypeField: string = 'iCoordType';
  degreeTypeField: string = 'iDegreeType';
  projectionField: string = "iProj";

  labels = {
    a: 'x',
    b: 'y',
    c: 'H'
  }
  placeHolders = {
    a: '0',
    b: '0',
    c: '0'
  }
  degreeTypes: string[] = ['D', 'DM', 'DMS'];

  unit: string = 'm';

  coordsArrayInDecimalsDegrees: Decimal[][][] = [];

  integerPattern = /^-?[0-9]*$/
  floatPattern = /^[+-]?\d+(\.\d+)?$/

  constructor(private fb: FormBuilder, private formattingService: FormattingService, private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.coordTypeField = (this.isInput) ? "iCoordType" : "oCoordType";
    this.degreeTypeField = (this.isInput) ? "iDegreeType" : "oDegreeType";
    this.projectionField = (this.isInput) ? "iProj" : "oProj";
    this.setFormValue(this.degreeTypeField, 'D');
    if (this.isInput) {
      this.addCoordRow(); // default input row
    }
    this.onDegreeTypeChange();
    this.refresh(this.fg.value[this.coordTypeField]);
  }

  /* this is required to avoid error ng0100 'Expression has changed after it was checked' when calling 'handleSubmittedCoordinates' */
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  get coordsArray(): any {
    const coordsArray = (this.isInput) ? "iCoordsArray" : "oCoordsArray";
    return this.fg.controls[coordsArray] as FormArray;
  }

  refresh(newCoordType?: string): void {
    let coordType;
    if (typeof newCoordType !== 'undefined') {
      coordType = newCoordType;
    } else {
      coordType = this.fg.value[this.coordTypeField];
    }
    this.labels = this.formattingService.fetchCoordLabels(coordType, this.fg.value[this.projectionField]);
    if (coordType == 'GEOGRAPHIC') {
      this.unit = this.degreeTypes[0];
      this.setFormValue(this.degreeTypeField, this.unit);
    } else {
      this.unit = 'm';
    }
    this.reset();
    this.setPlaceHolders();
  }

  addCoordRow(): void {
    const requiredValidator = (this.isInput) ? Validators.required : null;
    const iCoordGroup = this.fb.group({
      a: ['', requiredValidator],
      b: ['', requiredValidator],
      c: ['']
    });
    this.coordsArray.push(iCoordGroup);
  }

  removeCoordRow(i: number): void {
    this.coordsArray.removeAt(i);
  }

  reset() {
    while (this.coordsArray.length !== 0) {
      this.coordsArray.removeAt(0)
    }
    if (this.isInput) {
      this.addCoordRow();
    }
    this.coordsArrayInDecimalsDegrees = [];
  }

  onDegreeTypeChange() {
    this.fg.get(this.degreeTypeField)?.valueChanges
      .pipe(startWith('D'), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev !== next) {
          if (this.coordsArrayInDecimalsDegrees.length === 0) {
            this.storeInputAsDecimalDegrees(prev);
          }
          // convert from decimals degrees
          for (let i: number = 0; i < this.coordsArrayInDecimalsDegrees.length; i++) {
            const a = this.coordsArrayInDecimalsDegrees[i][0];
            const b = this.coordsArrayInDecimalsDegrees[i][1];
            if (a) {
              const nextA = this.formattingService.convert(a, 'D', next);
              this.coordsArray.at(i).get('a').setValue(this.formattingService.coordAsString(nextA, next));
            }
            if (b) {
              const nextB = this.formattingService.convert(b, 'D', next);
              this.coordsArray.at(i).get('b').setValue(this.formattingService.coordAsString(nextB, next));
            }
          }
          this.unit = next;
          this.setPlaceHolders();
        }
      });
  }

  formatInput(row: number, field: string, event: any) {
    // reset data in degrees
    this.coordsArrayInDecimalsDegrees = [];
    let value = event.target.value;
    if (this.unit != 'm') {
      const degreeType = this.fg.get(this.degreeTypeField)?.value;
      switch (field) {
        case 'a':
          value = this.formattingService.formatInputCoord(value, degreeType, false);
          break;
        case 'b':
          value = this.formattingService.formatInputCoord(value, degreeType, true);
          break;
        case 'c':
          value = this.formattingService.formatInputDecimal(value);
      }
    } else {
      value = this.formattingService.formatInputDecimal(value);
    }
    this.coordsArray.at(row).get(field).setValue(value);
  }

  handleSubmittedCoordinates(): string[][] {
    let coords: string[][] = [];
    for (let i: number = 0; i < this.coordsArray.value.length; i++) {
      const a = this.coordsArray.at(i).get('a').value;
      const b = this.coordsArray.at(i).get('b').value;
      const c = this.coordsArray.at(i).get('c').value;
      let aCoord = a;
      let bCoord = b;
      let cCoord = (c === '') ? 0 : c;
      if (this.unit == 'm') {
        this.validateNumericCoordinates(i, 'a', a);
        this.validateNumericCoordinates(i, 'b', b);
      } else {
        if (a !== '') {
          const aCoordRaw = this.formattingService.parseCoord(a, this.unit);
          this.validateLonLatCoordinates(i, 'a', aCoordRaw);
          aCoord = this.formattingService.convert(aCoordRaw, this.unit, 'D').toString();
        }
        if (b !== '') {
          const bCoordRaw = this.formattingService.parseCoord(b, this.unit);
          this.validateLonLatCoordinates(i, 'b', bCoordRaw);
          bCoord = this.formattingService.convert(bCoordRaw, this.unit, 'D').toString();
        }
      }
      this.validateNumericCoordinates(i, 'c', c);
      const coord = [aCoord, bCoord, cCoord];
      coords.push(coord);
    }
    return coords;
  }

  handleReceivedCoordinates(oCoords: any) {
    // reset data in degrees
    this.coordsArrayInDecimalsDegrees = [];
    this.storeOutputAsDecimalDegrees(oCoords);
    // display data in requested format
    for (let i: number = 0; i < oCoords.length; i++) {
      this.addCoordRow();

      const row = oCoords[i].values;
      const aInDecimal = this.formattingService.parseCoord(row[0], 'D');
      const aInSelectedUnit = this.formattingService.convert(aInDecimal, 'D', this.unit);
      const aFormatted = this.formattingService.coordAsString(aInSelectedUnit, this.unit);
      this.coordsArray.at(i).get('a').setValue(aFormatted);

      const bInDecimal = this.formattingService.parseCoord(row[1], 'D');
      const bInSelectedUnit = this.formattingService.convert(bInDecimal, 'D', this.unit);
      const bFormatted = this.formattingService.coordAsString(bInSelectedUnit, this.unit);
      this.coordsArray.at(i).get('b').setValue(bFormatted);

      const c = row[2];
      this.coordsArray.at(i).get('c').setValue(c);
    }
  }

  private validateNumericCoordinates(index: number, field: string, value: string) {
    let isError = false;
    if ((field === 'c') && (value === '')) {
      isError = false;
    }
    else if (!(this.floatPattern).test(value)) {
      isError = true;
    }
    const error = isError ? { forbiddenName: { value: value } } : null;
    this.coordsArray.controls[index].get(field).setErrors(error);
  }

  private validateLonLatCoordinates(index: number, field: string, coord: Decimal[]) {
    let isError = false;
    const degLowerLimit = (field === 'a') ? new Decimal(-90) : new Decimal(-180);
    const degUpperLimit = (field === 'a') ? new Decimal(90) : new Decimal(180);
    const d = coord[0];
    const m = (coord[1]) ? coord[0] : new Decimal(0);
    const s = (coord[2]) ? coord[0] : new Decimal(0);
    if ((d.lessThan(degLowerLimit)) || (d.greaterThan(degUpperLimit))) {
      isError = true;
    }
    if ((m.lessThan(0)) || (m.greaterThan(60))) {
      isError = true;
    }
    if ((s.lessThan(0)) || (s.greaterThan(60))) {
      isError = true;
    }
    const error = isError ? { forbiddenName: { value: coord } } : null;
    this.coordsArray.controls[index].get(field).setErrors(error);
  }

  private setFormValue(field: string, value: any) {
    this.fg.patchValue({
      [field]: value
    });
  }

  private setPlaceHolders() {
    switch (this.unit) {
      case 'D':
        this.placeHolders.a = "0";
        this.placeHolders.b = "00";
        break;
      case 'DM':
        this.placeHolders.a = "0°00";
        this.placeHolders.b = "00°00";
        break;
      case 'DMS':
        this.placeHolders.a = "0°00'00";
        this.placeHolders.b = "00°00'00";
        break;
      default:
        this.placeHolders.b = '0';
        this.placeHolders.a = '0';
    }
  }

  private storeInputAsDecimalDegrees(fromUnit: string) {
    for (let i: number = 0; i < this.coordsArray.value.length; i++) {
      this.coordsArrayInDecimalsDegrees[i] = [];

      let a = this.coordsArray.at(i).get('a').value;
      a = (a) ? a : '0';
      const parsedAInUnit: Decimal[] = this.formattingService.parseCoord(a, fromUnit);
      const parsedAInDegrees: Decimal[] = this.formattingService.convert(parsedAInUnit, fromUnit, 'D');
      this.coordsArrayInDecimalsDegrees[i][0] = parsedAInDegrees;

      let b = this.coordsArray.at(i).get('b').value;
      b = (b) ? b : '0';
      const parsedBInUnit: Decimal[] = this.formattingService.parseCoord(b, fromUnit);
      const parsedBInDegrees: Decimal[] = this.formattingService.convert(parsedBInUnit, fromUnit, 'D');
      this.coordsArrayInDecimalsDegrees[i][1] = parsedBInDegrees;
    }
  }

  private storeOutputAsDecimalDegrees(oCoords: any) {
    for (let i: number = 0; i < oCoords.length; i++) {
      this.coordsArrayInDecimalsDegrees[i] = [];
      const row = oCoords[i].values;

      let a = row[0];
      a = (a) ? a : '0';
      const parsedAInDegrees = this.formattingService.parseCoord(a, 'D');
      this.coordsArrayInDecimalsDegrees[i][0] = parsedAInDegrees;

      let b = row[1];
      b = (b) ? b : '0';
      const parsedBInDegrees = this.formattingService.parseCoord(b, 'D');
      this.coordsArrayInDecimalsDegrees[i][1] = parsedBInDegrees;
    }
  }

}
