<ng-container [formGroup]="fg">
        <div [style.visibility]="(unit==='m')? 'hidden' : 'visible'">
                <mat-radio-group [name]="degreeTypeField" [formControlName]="degreeTypeField">
                        <mat-radio-button *ngFor="let  dt of degreeTypes" [value]="dt" class="radio">
                                <span class="radio-spaced"> {{dt}} </span>
                        </mat-radio-button>
                </mat-radio-group>
        </div>
        <br>
        <ng-container [formArrayName]="(isInput) ? 'iCoordsArray' : 'oCoordsArray'">
                <ng-container *ngFor="let control of coordsArray.controls ;let i = index;">
                        <fieldset class="coordEntry" [formGroupName]="i">
                                <legend>coordinates {{i+1}}</legend>
                                <div>
                                        <mat-form-field>
                                                <mat-label>{{ labels.a }}</mat-label>
                                                <input matInput type="text" [placeholder]="placeHolders.a"
                                                        formControlName="a" #inputA (input)="formatInput(i,'a',$event)"
                                                        maxlength="15" [readonly]="!isInput"
                                                        [ngStyle]="{'width.ch': inputA.value.length, 'min-width.ch': placeHolders.a.length}"><span
                                                        [class]="'unit_'+unit"></span>
                                                <!--(focusout)="formatInput(i,'c',$event.target)"-->
                                                <mat-error
                                                        *ngIf="coordsArray.controls[i].get('a').hasError('forbiddenName')">Invalid
                                                        input</mat-error>
                                        </mat-form-field>
                                </div>
                                <div>
                                        <mat-form-field>
                                                <mat-label>{{ labels.b }}</mat-label>
                                                <input matInput type="text" [placeholder]="placeHolders.b"
                                                        formControlName="b" #inputB (input)="formatInput(i,'b',$event)"
                                                        maxlength="15" [readonly]="!isInput"
                                                        [ngStyle]="{'width.ch': inputB.value.length, 'min-width.ch': placeHolders.b.length}"><span
                                                        [class]="'unit_'+unit"></span>
                                                <mat-error
                                                        *ngIf="coordsArray.controls[i].get('b').hasError('forbiddenName')">Invalid
                                                        input</mat-error>
                                        </mat-form-field>
                                </div>
                                <div>
                                        <mat-form-field>
                                                <mat-label>{{ labels.c }}</mat-label>
                                                <input matInput type="text" [placeholder]="placeHolders.c"
                                                        formControlName="c" #inputC (input)="formatInput(i,'c',$event)"
                                                        maxlength="15" [readonly]="!isInput"
                                                        [ngStyle]="{'width.ch': inputC.value.length, 'min-width.ch': placeHolders.c.length}">
                                                <mat-error
                                                        *ngIf="coordsArray.controls[i].get('c').hasError('forbiddenName')">Invalid
                                                        input</mat-error>
                                        </mat-form-field>
                                </div>
                                <button [style.visibility]="isInput? 'visible': 'hidden'" mat-icon-button color="warn"
                                        type="button" (click)="removeCoordRow(i)"><mat-icon>delete</mat-icon></button>
                        </fieldset>
                </ng-container>
                <button *ngIf="isInput" mat-icon-button color="warn" type="button"
                        (click)="addCoordRow()"><mat-icon>add_box</mat-icon></button>
        </ng-container>
</ng-container>