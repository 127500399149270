export class ConversionRequest {

    date: any = null;

    constructor(
        public iDatum: string,
        public iProj: string,
        public iCoordType: string,
        public oDatum: string,
        public oProj: string,
        public oCoordType: string,
        public degreeType: string,
        public iCoords: string[][]) {
    }

    setDate(date: any) {
        this.date = date;
    }

}