import { Component, OnInit } from '@angular/core';

export type OutputCoords = {
  values: string[],
  heightMap: HeightMap,
}

export type HeightMap = {
  defaultKey: string,
  heights: any[]
}

export type HeightView = {
  label: string,
  value: any
}

@Component({
  selector: 'app-height-data',
  templateUrl: './height-data.component.html',
  styleUrls: ['./height-data.component.scss']
})
export class HeightDataComponent implements OnInit {

  heightSeries: HeightView[][] = [];
  isMissingHeights: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  handleReceivedHeights(oCoords: any, requestedHeights: string[]) {
    this.isMissingHeights = false;
    for (let i: number = 0; i < oCoords.length; i++) {
      const serie: HeightView[] = [];
      const coord = oCoords[i] as unknown as OutputCoords;
      const heights = coord.heightMap.heights;
      requestedHeights.forEach((h: any) => {
        if (h in heights) {
          serie.push({
            label: h,
            value: heights[h]
          });
        } else {
          this.isMissingHeights = true;
          serie.push({
            label: h,
            value: " missing"
          });
        }
      });
      this.heightSeries.push(serie);
    }
  }

  reset() {
    this.heightSeries = [];
  }

}
