<h1>Documentation</h1>
<br>
<h2>The CoordinateTransformations web application</h2>
<p>De Nederlandstalige handleiding kan u <a href="assets/pdf/cConvert_Nederlands.pdf">hier</a> vinden.</p>
<p>Le manuel d'utilisation en français est disponible <a href="assets/pdf/cConvert_francais.pdf">ici</a>.</p>
<p>See the English user manual <a href="assets/pdf/cConvert_English.pdf">here</a>.</p>
<br>
<h2>The CoordinateTransformations API</h2>
<p>See OpenAPI documentation <a href="{{apiPath}}/doc">here</a>.</p>
<br>
<h2>The CoordinateTransformations desktop application</h2>
<p>Please <a id="contact" (click)="contact()">contact us</a> for further information.</p>
<br>