import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material/material.module';

import { TransformationFormComponent } from './transformation-form.component';
import { DropzoneDirective } from 'src/app/directives/dropzone.directive';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { CoordinateDataComponent } from './coordinate-data/coordinate-data.component';
import { HeightDataComponent } from './height-data/height-data.component';

@NgModule({
  declarations: [
    TransformationFormComponent,
    DropzoneDirective,
    CoordinateDataComponent,
    HeightDataComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    ProgressBarModule
  ],
  exports: [
    TransformationFormComponent,
    CoordinateDataComponent,
    HeightDataComponent,
  ]
})
export class TransformationFormModule { }
