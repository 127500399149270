import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiErrorDescription } from '../models/ApiErrorDescription';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorDocService {

  apiPath = environment.apiPath;

  private dataLoadedSubject: Subject<boolean>;
  readonly $dataLoaded: Observable<boolean>;
  errorDescriptions: ApiErrorDescription[] = [];

  constructor(private http: HttpClient) {
    this.dataLoadedSubject = new Subject<any>();
    this.$dataLoaded = this.dataLoadedSubject.asObservable();
    this.fetchErrorMessages();
  }

  private fetchErrorMessages() {
    let serviceUrl = this.apiPath + "/fetchApiErrorMessages";
    return this.http.get<any>(serviceUrl).subscribe((apiErrorList: any) => {
      console.log("received : " + JSON.stringify(apiErrorList));
      for (let e of apiErrorList) {
        this.errorDescriptions.push(new ApiErrorDescription(e.id, e.title, e.status, e.description));
      }
      this.dataLoadedSubject.next(true);
    }, error => {
      console.log(error);
    });
  }

  listErrorDescriptions() {
    return this.errorDescriptions
  }

  findErrorDescription(id: string | null) {
    return this.errorDescriptions.find((ed) => {
      return ed.id === id;
    });
  }

}
